import React from 'react'
import Container from '../components/layout/container'
import Layout from '../components/layout/layout'
import Seo from '../components/seo/seo'
import Content from '../components/ui/content'
import PageHeader from '../components/ui/page-header'
import { StaticImage } from 'gatsby-plugin-image'

const About = () => {
  return (
    <Layout menuLang='en'>
      <Seo title='About' description='Sayin Textile' article lang='en' />
      <PageHeader title='About' />
      <Container className='py-10 text-center'>
        <h2 className='text-5xl text-white my-5'>About Sayin Textile</h2>
        <StaticImage src='../images/hr1.png' />
      </Container>
      <Container className='flex flex-wrap'>
        <div className='w-full md:w-1/2'>
          <div className='prose prose-sm md:prose-md font-light text-white md:pr-5'>
            <p>
              Our company’s roots date back to Mr. Halil SAYIN’s business in
              1960s as a sole-proprietorship. In 1997, it became a corporation
              namely SAYIN TEXTILE INDUSTRY AND TRADE LIMITED COMPANY.
            </p>

            <p>
              In the first years, its business was mainly trading of raw,
              printed and dyed fabrics. In 1997, the first in-house production
              unit – the Warping and Sizing facility – was installed on 7.400m2
              land with 4.800m2 closed area. In 2002, Sayin Textile Ltd. bought
              Sulzer weaving machines and started a weaving facility in its
              factory. The number of machines had increased in years and reached
              56 in 2014. In 2015, 10 Itema weaving machines was added to the
              weaving line. Today, the company has 66 machines varying from
              280cm to 390cm reed width.
            </p>

            <p>
              Currently, Sayin Textile Ltd. produces raw and finished fabrics
              for home, hotel and clothing industry. It has its own dyed fabrics
              collection. It also produces various ready-made articles for local
              and export markets.
            </p>
          </div>
        </div>
        <div className='w-full md:w-1/2'>
          <StaticImage
            src='../images/DSC_0070-1.jpg'
            alt='Sayın Textile Factory'
          />
        </div>
      </Container>
    </Layout>
  )
}

export default About
